<template>
    <Transition name="fade" mode="out-in" appear>
        <Outro
            v-if="step === STEP.OUTRO"
            :redirect-text="post_onboarding_action_text"
            :redirect-path="post_onboarding_redirect"
            :first-name="user_details.first_name"
            :current-date="current_date"
            :logo-url="logoUrl"
        ></Outro>
        <div v-else class="px-6 py-4 grid grid-cols-12 gap-8 h-screen w-full overflow-hidden bg-white">
            <div class="col-span-6 flex flex-col items-center justify-center gap-10">
                <div>
                    <img height="48" class="h-12 w-auto mx-auto block" :src="logoUrl" />
                </div>
                <Personalization
                    v-if="step === STEP.PERSONALIZATION"
                    :errors="errors"
                    :default-language="default_language"
                    :language-options="language_options"
                    @submit="onPersonalizationSubmit"
                />
                <Questions
                    v-else-if="step === STEP.QUESTIONS"
                    :disabled="isLoading"
                    :current-question="currentQuestion"
                    :current-question-position="currentQuestionIndex + 1"
                    :questions-count="questions.length"
                    @answer="handleQuestionAnswer"
                    @next="handleNextQuestion"
                    @recording-level="handleRecordingLevel"
                    @recording-start="handleRecordingStart"
                    @recording-stop="handleRecordingStop"
                    @transcribe-start="isTranscribing = true"
                    @transcribe-end="isTranscribing = false"
                >
                </Questions>
                <Insights v-else-if="step === STEP.INSIGHTS" :insights="insights" @done="handleDone"></Insights>
                <div class="w-full max-w-sm flex items-center justify-center">
                    <Transition name="fade">
                        <div v-if="showPrivacyDialog" class="flex-1">
                            <PrivacyDialog :disabled="isRecordingProcessing || isLoading" />
                        </div>
                    </Transition>
                    <Transition name="fade">
                        <button
                            v-if="showSkip"
                            type="button"
                            :disabled="isLoading || isRecordingProcessing"
                            class="flex-1 button button-text"
                            data-cy="skip-personalization"
                            @click="handleSkip"
                        >
                            Skip personalization <i class="bi bi-arrow-right" />
                        </button>
                    </Transition>
                </div>
            </div>
            <div class="col-span-6 flex flex-col gap-10 py-8 px-6 justify-center items-center gradient-background rounded-[30px]">
                <div>
                    <CoachingModeMarble :is-loading="true" size="big" />
                </div>
                <div class="flex flex-col gap-2 mx-auto max-w-md text-center">
                    <div class="px-2 max-h-96 overflow-y-auto text-2xl font-semibold text-[#262626] tracking-tighter leading-snug" v-text="rightHeadline"></div>
                    <div class="px-2 text-base font-medium text-[#868686] leading-normal" v-text="rightBody"></div>
                </div>
                <Transition name="fade">
                    <ContextMeter v-if="isRecording" :running="contextMeterRunning" />
                </Transition>
                <Transition name="fade" mode="in-out">
                    <div
                        v-if="showTimeEstimate"
                        class="inline-block mx-auto rounded-lg font-semibold text-base text-[#525252] bg-white p-2 leading-tight border border-2 border-[#E5BCEF]"
                    >
                        3 min
                    </div>
                </Transition>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { getCookie } from "/js/utils.js";
import { router } from "@inertiajs/vue3";
import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import ContextMeter from "~vue/Onboarding/ContextMeter.vue";
import Insights from "~vue/Onboarding/Insights.vue";
import Outro from "~vue/Onboarding/Outro.vue";
import Personalization from "~vue/Onboarding/Personalization.vue";
import PrivacyDialog from "~vue/Onboarding/PrivacyDialog.vue";
import Questions from "~vue/Onboarding/Questions.vue";
import { logUserInteraction } from "~vue/utils/logUtils";
import throttle from "lodash.throttle";
import { computed, inject, onMounted, onUnmounted, ref } from "vue";

const STEP = {
    PERSONALIZATION: "personalization",
    QUESTIONS: "questions",
    INSIGHTS: "insights",
    OUTRO: "outro",
};

const { $setEventContext } = inject("globalProperties");
const props = defineProps({
    current_date: String,
    organization_logo_url: String,
    errors: Object,
    language_options: Array,
    default_language: String,
    insights: Array,
    step: String,
    event_context: Object,
    user_details: Object,
    post_onboarding_redirect: String,
    post_onboarding_action_text: String,
});

const isLoading = ref(false);
const isRecording = ref(false);
const isTranscribing = ref(false);
const recordingLevelSamplingRate = ref(200);

const contextMeterRunning = ref(false);
const rightHeadline = ref("I'm Nadia, your safe space at work");
const rightBody = ref("To make sure I'm the best thinking partner for you, I'd love to get to know you better. ");

const questions = ref([
    {
        text: "Tell me more about your day to day? What are you currently working on, and what does your team look like?",
        answer: null,
        llmResponse: null,
        offTopic: false,
    },
    {
        text: "Has anyone given you feedback recently that has stuck with you? Or are there specific areas you want to grow in that we can focus on first?",
        answer: null,
        llmResponse: null,
        offTopic: false,
    },
]);

const currentQuestionIndex = ref(0);
const currentQuestion = computed(() => questions.value[currentQuestionIndex.value]);
const showSkip = computed(() => props.step === STEP.QUESTIONS);
const showPrivacyDialog = computed(() => {
    // PrivacyDialog is more integrated in the personalization and outro step, so it's added there.
    return [STEP.QUESTIONS, STEP.INSIGHTS].includes(props.step);
});

const isRecordingProcessing = computed(() => isRecording.value || isTranscribing.value);
const logoUrl = computed(() => props.organization_logo_url || "/static/logo.png");
const showTimeEstimate = computed(
    () => [STEP.PERSONALIZATION, STEP.QUESTIONS].includes(props.step) && !isLoading.value && !isRecordingProcessing.value && questions.value.every((q) => q.answer === null),
);

onMounted(() => {
    $setEventContext(props.event_context);
});

onUnmounted(() => {
    $setEventContext({});
});

function handleNextQuestion() {
    logUserInteraction("onboarding_next_clicked", {}, true);
    if (currentQuestionIndex.value === questions.value.length - 1) {
        postResponse(
            {
                intent: "insights",
                form: questions.value.map((q) => ({ question: q.text, answer: q.answer })),
            },
            {
                only: ["step", "errors", "insights"],
                onFinish: () => {
                    rightHeadline.value = "Based on what you’ve shared, I have some thoughts to guide our journey together";
                    rightBody.value = "Let's go through them together";
                },
            },
        );
    } else {
        rightHeadline.value = "I'll remember that";
        rightBody.value = "Over time, I will learn about you so you'll get more specific, personalized support in every chat";
        currentQuestionIndex.value++;
    }
}

const handleRecordingLevel = computed(() =>
    throttle(({ isBelowSilenceThreshold }) => {
        /*
         * Check whether user is silent. Context meter should only run
         * when we detect signal.
         * If silence is detected, then the sampling rate is
         * increased so that signal is detected sooner. If signal is detected,
         * the sampling rate is decreased to accomodate natural pausing.
         */
        contextMeterRunning.value = !isBelowSilenceThreshold;
        if (isBelowSilenceThreshold) {
            recordingLevelSamplingRate.value = 200;
        } else {
            recordingLevelSamplingRate.value = 4000;
        }
    }, recordingLevelSamplingRate.value),
);

function handleRecordingStart() {
    isRecording.value = true;
}

function handleRecordingStop() {
    isRecording.value = false;
}

async function handleQuestionAnswer({ answer, append, onFinish }) {
    let nextAnswer;
    isLoading.value = true;

    const currentAnswer = questions.value[currentQuestionIndex.value].answer;
    if (append && currentAnswer !== null) {
        nextAnswer = `${currentAnswer}\n\n${answer}`;
    } else {
        logUserInteraction(`onboarding_q${currentQuestionIndex.value + 1}_completed`, {}, true);
        nextAnswer = answer;
    }

    try {
        const response = await fetch("/coach/active_listening", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
            body: JSON.stringify({
                form: {
                    question: questions.value[currentQuestionIndex.value].text,
                    answer: nextAnswer,
                },
            }),
        });

        const payload = await response.json();

        if (payload.status === "guardrails_violated") {
            rightHeadline.value = "Sorry, let’s try again";
            rightBody.value = "Let's get back on track and discuss topics about your role, your position, and your team at work.";
            questions.value[currentQuestionIndex.value].offTopic = true;
            return;
        }

        questions.value[currentQuestionIndex.value].answer = nextAnswer;
        questions.value[currentQuestionIndex.value].llmResponse = payload.content;
        questions.value[currentQuestionIndex.value].offTopic = false;

        rightHeadline.value = payload.content;
        rightBody.value = "Just to make sure - did I hear this right? You can always share more context.";
    } catch (e) {
        // TODO: Show error to user
        console.error(e);
    } finally {
        isLoading.value = false;
        onFinish();
    }
}

function postResponse(payload, { only = [], onFinish = () => {} } = {}) {
    isLoading.value = true;

    router.post("", payload, {
        only,
        headers: {
            "X-CSRFToken": getCookie("csrftoken"),
        },
        onFinish: () => {
            isLoading.value = false;
            onFinish();
        },
    });
}

function handleDone({ onFinish }) {
    postResponse({ intent: "done" }, { only: ["step", "user_details", "post_onboarding_redirect", "post_onboarding_action_text"], onFinish });
}

function handleSkip() {
    postResponse({ intent: "skip" });
}

function onPersonalizationSubmit(formValues) {
    postResponse({ intent: "personalization", form: formValues }, { only: ["step", "errors", "user_details"] });
}
</script>

<style type="postcss">
.error-list li {
    @apply text-xs tracking-tighter font-medium text-red-700 text-left;
}

.button {
    @apply flex justify-center items-center gap-1 transition-colors ease-in duration-150 rounded-[20px] px-9 py-3 leading-none font-semibold tracking-tighter text-base w-full ring-2 ring-inset;
}

.button-text {
    @apply px-6 py-3 transition-colors ease-in duration-150 font-semibold text-base leading-none ring-0;
}

.button-text:not(.button-text--muted) {
    @apply text-[#555BA2] hover:text-[#4B508F];
}

.button-text--muted {
    @apply hover:text-[#7C7C7C] text-[#8C8C8C];
}

.button-primary {
    @apply bg-[#555BA2] hover:bg-[#4B508F] hover:ring-[#4B508F] text-white ring-[#555BA2];
}

.button-danger {
    @apply text-white ring-[#CF1322] bg-[#CF1322];
}

.button-primary:disabled,
.button-danger:disabled {
    @apply bg-[#F5F5F5] ring-[#F5F5F5] text-[#8C8C8C];
}

.button-text:disabled {
    @apply opacity-40;
}

.gradient-background {
    background: linear-gradient(360deg, #e9defa 0%, #fff6eb 100%);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
