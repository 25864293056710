<template>
    <div class="h-screen w-full overflow-hidden bg-white relative">
        <div class="p-6 max-w-7xl mx-auto grid grid-cols-12 gap-8 h-full">
            <div class="bubble bubble-left"></div>
            <div class="bubble bubble-right"></div>
            <div :class="scheduled ? 'single-column--narrow' : 'single-column--wide'" class="z-20 overflow-hidden flex flex-col gap-6">
                <div class="shrink-0">
                    <img height="36" class="h-9 w-auto mx-auto block" :src="logoUrl" />
                </div>
                <div class="shrink-0 text-center flex flex-col gap-3 tracking-tighter">
                    <time class="text-base font-semibold text-[#8C8C8C]">{{ currentDate }}</time>
                    <div class="px-3 font-semibold text-2xl text-[#26262]">I&apos;ve learned a little bit about you, {{ firstName }}!</div>
                </div>
                <div
                    class="transition-[width] overflow-hidden bg-white rounded-2xl p-6 grid grid-cols-12 grid-rows-1 items-center gap-10 tracking-tighter content-shadow border border-2 border-[#F5F5F5]"
                >
                    <div :class="scheduled ? 'col-span-12' : 'col-span-6'" class="flex flex-col gap-6 items-center">
                        <div>
                            <CoachingModeMarble :is-loading="true" size="big" />
                        </div>
                        <div class="flex flex-col text-center gap-2 px-2 max-w-xs mx-auto">
                            <div class="font-semibold text-2xl text-[#262626]" v-text="headline"></div>
                            <div class="text-base font-medium text-[#8C8C8C]" v-text="lede"></div>
                        </div>
                        <Transition name="fade">
                            <div v-if="error" v-text="error.message" class="text-sm tracking-tighter font-medium text-red-700 text-left"></div>
                        </Transition>
                        <div class="mx-auto flex flex-col gap-2">
                            <button v-if="!scheduled" type="button" class="button button-primary" :disabled="loading" @click="handleSchedule">Sounds great</button>
                            <button
                                type="button"
                                class="button"
                                :class="scheduled ? 'button-primary' : 'button-text'"
                                :disabled="loading"
                                @click="handleHome"
                                v-text="redirectText"
                            ></button>
                        </div>
                    </div>
                    <div v-if="!scheduled" class="col-span-6 overflow-y-auto">
                        <ScheduleFollowUp
                            :is-collapsed="false"
                            :date="scheduleDate"
                            :recurring="scheduleRecurring"
                            :disabled="loading"
                            @date-update="handleDateUpdate"
                            @recurring-update="handleRecurringUpdate"
                        />
                    </div>
                </div>
                <div>
                    <PrivacyDialog />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, inject, ref } from "vue";
import { router } from "@inertiajs/vue3";
import { DateTime } from "luxon";

import CoachingModeMarble from "~vue/components/navigation/CoachingModeMarble.vue";
import PrivacyDialog from "~vue/Onboarding/PrivacyDialog.vue";
import ScheduleFollowUp from "~vue/ScheduleFollowUp.vue";
import { logError, logUserInteraction } from "~vue/utils/logUtils";

const props = defineProps({
    firstName: String,
    currentDate: String,
    logoUrl: String,
    redirectPath: String,
    redirectText: String,
});

const { $sendEvent } = inject("globalProperties");

const scheduleDate = ref(initializeDate());
const scheduleRecurring = ref(true);
const error = ref(null);
const loading = ref(false);
const scheduled = ref(false);

const luxonDate = () => DateTime.fromJSDate(scheduleDate.value);

const formattedDate = computed(() => {
    const date = luxonDate();
    return {
        day: date.day,
        month: date.monthShort,
        weekday: date.weekdayLong,
        time: date.toLocaleString(DateTime.TIME_SIMPLE),
    };
});

const headline = computed(() => {
    if (scheduled.value) {
        return `Ok great! See you next ${formattedDate.value.weekday} at ${formattedDate.value.time}.`;
    }

    return "Let's keep these conversations going";
});

const lede = computed(() => {
    if (scheduled.value) {
        return `I have sent an invite to your calendar for next ${formattedDate.value.weekday}. This is a ${scheduleRecurring.value ? "recurring" : "single"} event.`;
    }

    return "I'll check in with you weekly so you'll always have someone in your corner to process, plan, and problem-solve.";
});

function initializeDate() {
    const date = new Date(Date.now());
    date.setDate(date.getDate() + 2);
    return date;
}

function handleDateUpdate(newDate) {
    scheduleDate.value = newDate;
}

function handleRecurringUpdate(recurring) {
    scheduleRecurring.value = recurring;
}

async function handleSchedule() {
    try {
        error.value = null;
        loading.value = true;
        await $sendEvent("schedule_follow_up", {
            event_description: "Agenda: Check-in with Coach",
            event_at_confirmed: DateTime.fromJSDate(scheduleDate.value).toISO(),
            recurring: scheduleRecurring.value,
            type: "followup",
        });
        scheduled.value = true;
    } catch (e) {
        error.value = e;
        logError(e);
    } finally {
        loading.value = false;
    }
}

function handleHome() {
    router.visit(props.redirectPath, {
        onSuccess: () => {
            logUserInteraction("onboarding_home_reached", {}, true);
        },
    });
}
</script>

<style scoped type="postcss">
.single-column--narrow {
    grid-column: 4 / span 5;
}

.single-column--wide {
    grid-column: 2 / span 10;
}

.content-shadow {
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.04);
}

.bubble {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    border-radius: 100%;
    backdrop-filter: blur(2px);
}

.bubble-right {
    transform: scale(1) translateY(-40%) translateX(30%);
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 63, 220, 0.12) 0%, rgba(0, 63, 220, 0) 100%);
}

.bubble-left {
    transform: scale(1) translateY(-40%) translateX(-30%);
    background: radial-gradient(50% 50% at 50% 50%, rgba(254, 40, 144, 0.12) 0%, rgba(254, 40, 144, 0) 100%);
}
</style>
