<template>
    <div class="stacked-grid">
        <div class="stacked-grid-item">
            <div class="leading-none text-center tracking-tighter font-semibold text-[#555BA2]">{{ currentInsightIndex + 1 }} of {{ insights.length }}</div>
            <div class="tracking-tighter text-left w-full bg-white py-4 flex flex-col gap-3 rounded-3xl border border-2 border-black/12">
                <div class="px-6">
                    <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.3014 11.57L16.3007 11.5707L2.75975 24.061L7.23416 14.8622L7.65474 13.9976L6.71373 13.8001L1.63169 12.7337L14.5183 1.5809L10.6929 9.44004L10.272 10.3048L11.2132 10.5023L16.3014 11.57Z"
                            stroke="black"
                            stroke-width="1.50005"
                        />
                    </svg>
                </div>
                <div class="px-6 font-semibold text-2xl text-[#262626]">{{ insights[currentInsightIndex].title }}</div>
                <div class="px-6 overflow-y-auto max-h-64 text-base font-medium text-black/50">{{ insights[currentInsightIndex].content }}</div>
            </div>
            <div class="flex flex-col items-center">
                <div class="flex items-center justify-center gap-6">
                    <button type="button" title="Previous" :disabled="isFirst || submitting" class="arrow-button" @click="handlePrevious">
                        <i class="bi bi-arrow-left-circle" />
                    </button>
                    <button type="button" title="Next" :disabled="isLast || submitting" class="arrow-button" @click="handleNext"><i class="bi bi-arrow-right-circle" /></button>
                </div>
                <Transition name="fade">
                    <div v-if="seenLast" class="inline-block mx-auto">
                        <button :disabled="submitting" type="button" class="button button-text" @click="handleDone">Done</button>
                    </div>
                </Transition>
            </div>
        </div>
        <div class="rotate-[-4deg] translate-x-[-60px] stacked-grid-item"></div>
        <div class="rotate-[6deg] translate-x-[55px] stacked-grid-item"></div>
    </div>
</template>

<script setup>
import { logUserInteraction } from "~vue/utils/logUtils";
import { computed, onMounted, ref, watch } from "vue";

const props = defineProps({
    insights: Array,
});

const emit = defineEmits("done");

const submitting = ref(false);
const currentInsightIndex = ref(0);
const seenLast = ref(false);
const isLast = computed(() => currentInsightIndex.value === props.insights.length - 1);
const isFirst = computed(() => currentInsightIndex.value === 0);

watch(currentInsightIndex, (val) => {
    if (!seenLast.value && val === props.insights.length - 1) {
        seenLast.value = true;
    }
    logUserInteraction(`onboarding_insight${currentInsightIndex.value + 1}_viewed`, {}, true);
});

onMounted(() => {
    logUserInteraction(`onboarding_insight${currentInsightIndex.value + 1}_viewed`, {}, true);
});

function handleNext() {
    currentInsightIndex.value++;
}

function handlePrevious() {
    currentInsightIndex.value--;
}

function handleDone() {
    submitting.value = true;
    emit("done", { onFinish: () => (submitting.value = false) });
}
</script>

<style scoped type="postcss">
.stacked-grid {
    @apply grid place-items-center max-w-md mx-auto justify-stretch w-full;
    grid-template-areas: "stacked";
}

.stacked-grid-item {
    @apply transition-colors border border-2 p-6 rounded-2xl flex flex-col gap-6 transition-all duration-300 ease-in-out;
    grid-area: stacked;
}

.stacked-grid-item:not(:last-child) {
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.02);
}

.stacked-grid-item:first-child {
    @apply z-20 bg-[#F0F5FF] w-full h-full border-[#E0E9FF];
}

.stacked-grid-item:not(:first-child) {
    @apply w-4/5 h-4/5 bg-white border-[#F5F5F5];
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.02);
}

.arrow-button {
    @apply text-3xl text-[#555BA2] hover:text-[#4B508F] disabled:opacity-50;
}
</style>
